.reports.badge-success{
    background-color: green !important;
    color: #FFF !important;
    padding: 3px 10px !important;
    border-radius: 15px;
    width: 80px;
}
.reports.badge-fail{
    background-color: rgb(160, 32, 32) !important;
    color: #FFF !important;
    padding: 3px 10px !important;
    border-radius: 15px;
    text-align: center;
    width: 80px;
}