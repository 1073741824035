/* pagination container for the coupons */
.marketing-coupons .pagination-container{
    display: flex;
    justify-content: flex-end;
    list-style: none;
    cursor: pointer;
    margin-top: 20px;
    text-decoration: none;
  }
  .pagination-container li, .pagination-container li a :hover{
    cursor: pointer;
    text-decoration: none;
  }
  .marketing-coupons .react-bs-table-pagination{
    display: none;
  }
  .marketing-coupons .dashboard-counts .title span{
    font-size: 1.1em;
    font-weight: 700;
  }
  .marketing-coupons .pagination-container .active a{
    color: #fff;
    background-color: #1E4359;
    border-color: #1E4359;
  }
  .marketing-coupons .pagination-container li a {
    padding: 0.5rem 0.75rem;
    /* margin-left: -1px; */
    line-height: 1.25;
    color: #1E4359;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  .marketing-coupons .pagination-container li a:hover, .marketing-coupons .pagination-container li a:focus{
    text-decoration: none;
  }
  @media only screen and (max-width: 767px) {
    .marketing-coupons .pagination-container {
      display: flex;
      list-style: none;
      cursor: pointer;
      margin-top: 20px;
      text-decoration: none;
      position: relative;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      overflow: hidden;
      padding-left: 0;
    }
  }
  .marketing-coupons .previous.disabled, .marketing-coupons .next.disabled {
    display: none;
  }
  .marketing-coupons .card-body .form-group .btn{
    padding: 4% 20%;
  }
