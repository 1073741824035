.td-column-function-even-example {
  font-weight: bold;
  color: green;
}

.tr-pending-orders {
  background-color: yellow;
}

.td-column-function-odd-example {
  font-weight: bold;
  color: red;
}

.td-column-function-aggregator-example {
  font-weight: bold;
  color: rgb(236, 109, 130);
}

.tr-transfered-orders {
  background-color: orange;
}

/* pagination container for the coupons */
.total-orders .pagination-container {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  cursor: pointer;
  margin-top: 20px;
  text-decoration: none;
}

.pagination-container li,
.pagination-container li a :hover {
  cursor: pointer;
  text-decoration: none;
}

.total-orders .react-bs-table-pagination {
  display: none;
}

.total-orders .dashboard-counts .title span {
  font-size: 1.1em;
  font-weight: 700;
}

.total-orders .pagination-container .active a {
  color: #fff;
  background-color: #1E4359;
  border-color: #1E4359;
}

.total-orders .pagination-container li a {
  padding: 0.5rem 0.75rem;
  /* margin-left: -1px; */
  line-height: 1.25;
  color: #1E4359;
  background-color: #fff;
  border: 1px solid #ddd;
}

.total-orders .pagination-container li a:hover,
.total-orders .pagination-container li a:focus {
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .total-orders .pagination-container {
    display: flex;
    list-style: none;
    cursor: pointer;
    margin-top: 20px;
    text-decoration: none;
    position: relative;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
    padding-left: 0;
  }
}

.total-orders .previous.disabled,
.total-orders .next.disabled {
  display: none;
}

.input-dominos-search {
  float: right;
  width: 31%;
  padding: 3px;
  border: 1px solid #ddd;
}

.bottomPagination{
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}
.topPagination{
 position: absolute;
  /* z-index: 990; */
}